import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { HomeOutlined, Brightness4, Brightness5 } from "@material-ui/icons";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import SiteData from "../../utils/SiteData/SiteData";

import "./Header.css";

const Header = () => {
  const history = useHistory();
  const [theme, setTheme] = useState("light");

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    document.documentElement.classList.add("color-theme-in-transition");
    setTheme(newTheme);
    document.documentElement.setAttribute("data-theme", newTheme);
    window.setTimeout(() => {
      document.documentElement.classList.remove("color-theme-in-transition");
    }, 1000);
  };

  return (
    <Box mb={4} className="header__container">
      <AppBar position="static" className="appbar">
        <Toolbar className="toolbar">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => history.push("/")}
            className="appbar__home"
          >
            <HomeOutlined />
          </IconButton>
          <Typography style={{ flexGrow: 1, marginRight: "16px" }}>
            {SiteData.name}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleTheme}
          >
            {theme === "light" ? <Brightness4 /> : <Brightness5 />}
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
