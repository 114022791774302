import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

import jsonData from "./country_codes.json";

const returnCountryCodes = () => {
  return JSON.parse(JSON.stringify(jsonData)).country_codes;
};

const SiteData = {
  name: "Diversion",
  about: {
    name: "Chirag Khurana",
    title: "Developer",
    info: {
      birthday: "04th August 1995",
      email: "me (at) chiragkhurana (dot) com",
      address: "Delhi",
      about: "Developer with Designer's State of Mind.",
    },
    socials: {
      Instagram: {
        link: "https://instagram.com/chirag.xp",
        text: "chirag.xp",
        icon: <InstagramIcon />,
      },
      Github: {
        link: "https://github.com/ckhurana",
        text: "ckhurana",
        icon: <GitHubIcon />,
      },
      Linkedin: {
        link: "https://linkedin.com/khuranachirag",
        text: "khuranachirag",
        icon: <LinkedInIcon />,
      },
      Twitter: {
        link: "https://twitter.com/ChiragKhurana95",
        text: "ChiragKhurana95",
        icon: <TwitterIcon />,
      },
    },
  },

  home: [
    {
      title: "Contactless WhatsApp",
      description: "Initiate a WhatsApp chat with unsaved contact number.",
      url: "/wachat",
      image:
        "https://g.foolcdn.com/image/?url=https%3A//g.foolcdn.com/editorial/images/608490/whatsapp-logo.png&w=2000&op=resize",
      tags: ["Utility", "Social"],
    },
    {
      title: "URL Shortner",
      description: "It shortens your bloody URLs",
      url: "/urlshort",
      // url: "http://3ck.me/admin",
      data: {
        api: "https://3ck.me/yourls-api.php",
      },
      image:
        "https://mybigplunge.com/wp-content/uploads/2018/04/url-shortener-theplungedaily.jpg",
      tags: ["Utility"],
    },
    {
      title: "Watch My Playground",
      description: "Experimental Work by yours truly CK.",
      url: "http://labs.chiragkhurana.com",
      image:
        "https://seosherpa.com/wp-content/uploads/2020/11/seo-experiments-header.png",
      tags: ["Experiment"],
    },
  ],

  wanewchat: {
    country_codes: [],
    getCountryCodes: returnCountryCodes,
  },
};

export default SiteData;
