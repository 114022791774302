import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import SiteData from "../../utils/SiteData/SiteData";

import "./WANewChat.css";

const WANewChat = (props) => {
  const location = useLocation();
  const [countryCode, setCountryCode] = useState("+91");
  const [number, setNumber] = useState("");
  const whatsapp_url = "https://wa.me/";

  const handleCountryChange = (event) => {
    setCountryCode(event.target.value);
  };

  const handleNumberChange = (event) => {
    if (event.target.value.length > 10) {
      console.log("Maximum 10 digits allowed.");
    } else if (isNaN(event.target.value)) {
      console.log("Not a digit");
    } else {
      setNumber(event.target.value);
    }
  };

  const handleWARedirect = (event) => {
    event.preventDefault();
    if (number.length === 10 && countryCode !== undefined) {
      window.location.href = whatsapp_url + countryCode + number;

      // Clear number in text field
      setTimeout(() => {
        setNumber("");
      }, 800);
    }
  };

  const appdata = SiteData.home.find((o) => o?.url === location?.pathname);

  return (
    <Box className="app_box" style={{ minHeight: "100%" }}>
      <Grid container direction="column" className="app_container">
        <Grid item className="app_title">
          <Typography className="app_title__head" variant="h6">
            <WhatsAppIcon className="app_title__wa_icon" />
            <div>{appdata.title}</div>
          </Typography>
          <Typography className="app_title__desc" variant="body2">
            {appdata.description}
          </Typography>
        </Grid>

        <Grid item className="app_content__item_container">
          <form
            noValidate
            onSubmit={handleWARedirect}
            className="app_content__item"
          >
            <Grid
              container
              spacing={2}
              justify="center"
              alignItems="center"
              className="app_content"
            >
              <Grid item sm={2} className="app_content__selector_container">
                <TextField
                  select
                  label="Country"
                  value={countryCode}
                  size="medium"
                  className="app_content__selector"
                  onChange={handleCountryChange}
                >
                  {SiteData.wanewchat.getCountryCodes().map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.label + " (" + option.code + ")"}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item sm={6} className="app_content__input_section">
                <FormControl
                  fullWidth
                  variant="outlined"
                  className="app_content__input_container"
                >
                  <InputLabel htmlFor="phone-number">Phone Number</InputLabel>
                  <OutlinedInput
                    id="phone-number"
                    value={number}
                    className="app_content__input"
                    onChange={handleNumberChange}
                    startAdornment={
                      <InputAdornment position="start">
                        {countryCode}
                      </InputAdornment>
                    }
                    labelWidth={110}
                  />
                </FormControl>
                <Button
                  type="submit"
                  className="app_content__button__item"
                  color="primary"
                >
                  <SendIcon className="app_content__button" />
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>

        <Grid item xs className="padding_item">
          <noscript></noscript>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WANewChat;
