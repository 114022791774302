import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import "./AuthDialog.css";
import PersonIcon from "@material-ui/icons/Person";

const AuthDialog = ({
  username,
  password,
  setUsername,
  setPassword,
  open,
  setOpen,
}) => {
  const [dialogUser, setDialogUser] = useState(username);
  const [dialogPass, setDialogPass] = useState(password);
  const [loggedIn, setLoggedIn] = useState(false);

  const handleDialogOpen = () => {
    setOpen(true);
  };
  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleUsernameChange = (e) => {
    setDialogUser(e.target.value.trim());
  };
  const handlePasswordChange = (e) => {
    setDialogPass(e.target.value.trim());
  };
  const handleUserLogin = () => {
    // Something
    if (dialogUser !== "" && dialogPass !== "") {
      setUsername(dialogUser);
      setPassword(dialogPass);
      setLoggedIn(true);
    } else {
      setUsername("");
      setDialogUser("");
      setPassword("");
      setDialogPass("");
    }
    handleDialogClose();
  };
  const handleUserLogout = () => {
    setUsername("");
    setDialogUser("");
    setPassword("");
    setDialogPass("");
    setLoggedIn(false);
    handleDialogClose();
  };

  return (
    <>
      <Button
        type="submit"
        className="auth_dialog__button"
        color="primary"
        onClick={handleDialogOpen}
      >
        <PersonIcon className="auth_dialog__button__icon" />
        <Typography className="auth_dialog__button__name">
          {username ? username : "Guest"}
        </Typography>
      </Button>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        className="auth_dialog"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="auth_dialog__title">
          User Details
        </DialogTitle>
        {!loggedIn && (
          <DialogContent className="auth_dialog__content">
            {/* <DialogContentText>
              Enter the username and password for this page
            </DialogContentText> */}
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              className="app_content"
            >
              <Grid item className="app_content__input_section">
                <FormControl
                  fullWidth
                  variant="outlined"
                  className="app_content__input_container"
                >
                  <InputLabel htmlFor="username">Username</InputLabel>
                  <OutlinedInput
                    id="username"
                    value={dialogUser}
                    className="app_content__input"
                    onChange={handleUsernameChange}
                    labelWidth={72}
                    autoComplete="off"
                  />
                </FormControl>
              </Grid>
              <Grid item className="app_content__input_section">
                <FormControl
                  fullWidth
                  variant="outlined"
                  className="app_content__input_container"
                >
                  <InputLabel htmlFor="passwd">Password</InputLabel>
                  <OutlinedInput
                    id="passwd"
                    type="password"
                    value={dialogPass}
                    variant="outlined"
                    className="app_content__input"
                    onChange={handlePasswordChange}
                    labelWidth={70}
                    autoComplete="off"
                  />
                </FormControl>
              </Grid>
            </Grid>

            <DialogActions>
              <Button
                onClick={handleDialogClose}
                className="auth_dialog__button"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleUserLogin}
                className="auth_dialog__button"
                color="primary"
              >
                Login
              </Button>
            </DialogActions>
          </DialogContent>
        )}
        {loggedIn && (
          <DialogContent className="auth_dialog__content">
            <DialogContentText>Logged in as {username}</DialogContentText>
            <DialogActions>
              <Button
                onClick={handleDialogClose}
                className="auth_dialog__button"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleUserLogout}
                className="auth_dialog__button"
                color="primary"
              >
                Logout
              </Button>
            </DialogActions>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

export default AuthDialog;
