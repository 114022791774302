import { Typography } from "@material-ui/core";
import SiteData from "../../utils/SiteData/SiteData";

import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__left">
        <Typography variant="body1" className="footer__name">
          {SiteData.name}
        </Typography>
      </div>
      <div className="footer__right">
        <Typography variant="body2" className="footer__copyright">
          Designed and Developed by{" "}
          <a href="http://chiragkhurana.com" target="_blank" rel="noreferrer">
            {SiteData.about.name}
          </a>
          .
        </Typography>
      </div>
    </div>
  );
};

export default Footer;
