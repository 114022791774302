import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  FormHelperText,
  IconButton,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
} from "@material-ui/core";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import AuthDialog from "../../components/AuthDialog/AuthDialog";
import SiteData from "../../utils/SiteData/SiteData";

import * as Utils from "../../utils/helpers";

import "./UrlShorten.css";

const UrlShorten = () => {
  const nodeUrl = require("url");
  const location = useLocation();
  const [link, setLink] = useState("");
  const [shortLink, setShortLink] = useState("");
  const [customKeyword, setCustomKeyword] = useState("");
  const [invalidKeywordError, setInvalidKeywordError] = useState("");
  const [invalidLinkError, setInvalidLinkError] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);

  const APP_DATA = SiteData.home.find((o) => o?.url === location?.pathname);
  const SHORT_URL_API = new nodeUrl.Url();
  SHORT_URL_API.parse(APP_DATA?.data?.api);

  // Fetch Task
  const fetchShortLink = async (url, keyword) => {
    var jsonBody = {
      url,
      action: "shorturl",
      username,
      password,
      format: "json",
    };

    jsonBody = keyword === "" ? jsonBody : { ...jsonBody, keyword };

    const req = {
      method: "POST",
      header: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams(jsonBody),
    };
    const res = await fetch(SHORT_URL_API.href, req);
    // console.log(res);
    const data = await res.json();

    if (data.status === "fail") {
      // console.log(data);
      setInvalidLinkError("Error: " + data.message);
      return "";
    }

    setInvalidLinkError("");

    return data.shorturl;
  };

  const handleLinkChange = (event) => {
    const url = event.target.value.trim();
    if (!Utils.validURL(url)) {
      setInvalidLinkError("Invalid URL");
      console.log("Invalid URL");
    } else {
      setInvalidLinkError("");
    }
    setLink(url);
  };

  const handleCustomKeywordChange = (event) => {
    const custom_keyword = event.target.value.trim();
    if (Utils.isAlphaNum(custom_keyword)) {
      setInvalidKeywordError("");
      setCustomKeyword(custom_keyword);
    } else {
      setInvalidKeywordError("Alphabets and Numbers only");
      console.log("Short URL can contain only alphanumeric characters.");
    }
  };

  const handleLinkSubmit = async (e) => {
    e.preventDefault();
    if (username === "" || password === "") {
      console.log("User details not entered.");
      setOpen(true);
      return;
    }
    setShortLink("");
    let url = link.trim();
    if (Utils.validURL(url)) setLink(url);
    let keyword = customKeyword.trim();

    if (url === "") {
      console.log("Empty URL");
      return;
    } else {
      console.log(url, keyword);
    }
    const shorturl = await fetchShortLink(link, keyword);
    if (shorturl !== "") {
      setShortLink(shorturl);
      setLink("");
      setCustomKeyword("");
    }
  };

  // const handleShortLinkClick = () => {
  //   console.log("Link clicked: ");
  // };
  const handleShortLinkCopy = () => {
    navigator.clipboard.writeText(shortLink);
  };

  return (
    <Box className="app_box" style={{ minHeight: "100%" }}>
      <Grid container direction="column" className="app_container">
        <Grid item className="app_title">
          <Grid container className="app_title__wrapper" justify="center">
            <Grid item sm={10} xs={12}>
              <Typography className="app_title__head" variant="h6">
                <InsertLinkIcon className="app_title__link_icon" />
                <div>{APP_DATA.title}</div>
              </Typography>
              <Typography className="app_title__desc" variant="body2">
                {APP_DATA.description}
              </Typography>
            </Grid>
            <Grid item sm={2} className="app_title__user">
              <AuthDialog
                {...{
                  username,
                  password,
                  setUsername,
                  setPassword,
                  open,
                  setOpen,
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item className="app_content__item_container">
          <form
            noValidate
            onSubmit={handleLinkSubmit}
            className="app_content__item"
          >
            <Grid
              container
              spacing={2}
              justify="center"
              alignItems="center"
              className="app_content"
            >
              <Grid item xs={12} sm={6} className="app_content__input_section">
                <FormControl
                  fullWidth
                  variant="outlined"
                  className="app_content__input_container"
                >
                  <InputLabel htmlFor="long-url">Long URL</InputLabel>
                  <OutlinedInput
                    id="long-url"
                    error={invalidLinkError !== ""}
                    value={link}
                    className="app_content__input"
                    autoComplete="off"
                    onChange={handleLinkChange}
                    labelWidth={70}
                  />
                  {invalidLinkError !== "" && (
                    <FormHelperText error id="short-url-error">
                      {invalidLinkError}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item sm={4} className="app_content__input_section">
                <FormControl
                  fullWidth
                  variant="outlined"
                  className="app_content__input_container"
                >
                  <InputLabel htmlFor="short-url">Custom URL</InputLabel>
                  <OutlinedInput
                    error={invalidKeywordError !== ""}
                    id="short-url"
                    value={customKeyword}
                    variant="outlined"
                    className="app_content__input"
                    placeholder="(Optional)"
                    onChange={handleCustomKeywordChange}
                    autoComplete="off"
                    startAdornment={
                      <InputAdornment position="start">
                        {SHORT_URL_API.host + "/"}
                      </InputAdornment>
                    }
                    labelWidth={90}
                  />
                  {invalidKeywordError !== "" && (
                    <FormHelperText error id="short-url-error">
                      {invalidKeywordError}
                    </FormHelperText>
                  )}
                </FormControl>
                <Button
                  type="submit"
                  className="app_content__button__item"
                  color="primary"
                >
                  <InsertLinkIcon className="app_content__button" />
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item className="padding_item">
          <Grid container justify="center">
            {shortLink !== "" && (
              <Card className="card_small">
                <div className="app_short_url_section card_small__container">
                  <CardActionArea
                    className="card_small__action_container"
                    href={shortLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <CardContent className="card__content">
                      <Typography
                        variant="body2"
                        component="p"
                        className="card__content__title"
                      >
                        {shortLink}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions className="app_short_url__button">
                    <IconButton
                      aria-label="clipboard"
                      onClick={handleShortLinkCopy}
                    >
                      <AssignmentIcon />
                    </IconButton>
                  </CardActions>
                </div>
              </Card>
            )}
          </Grid>
        </Grid>
        <Grid item xs className="padding_item">
          <noscript></noscript>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UrlShorten;
