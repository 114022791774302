import { Box, Grid, Tab, Tabs } from "@material-ui/core";
import { useState } from "react";
import AppCard from "../../components/AppCard/AppCard";
import SiteData from "../../utils/SiteData/SiteData";

import "./Home.css";

const Home = () => {
  const [tagValue, setTagValue] = useState("All");
  return (
    <Box className="app_box">
      <Grid
        container
        className="main_content"
        spacing={3}
        justify="space-between"
      >
        <Grid item xs={12}>
          <Tabs
            value={tagValue}
            className="tags"
            variant="scrollable"
            onChange={(event, newValue) => setTagValue(newValue)}
          >
            {[
              ...new Set(
                [
                  "All",
                  SiteData.home.map((content) => content.tags).flat(),
                ].flat()
              ),
            ].map((tag_item) => (
              <Tab
                label={tag_item}
                value={tag_item}
                className={
                  tagValue === tag_item ? "tags__item active" : "tags__item"
                }
              />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            justify="flex-start"
            className="apps__container"
          >
            {SiteData.home.map((content, id) => (
              <>
                {tagValue === "All" || content.tags.includes(tagValue) ? (
                  <Grid item md={4} sm={6} xs={12} className="apps__item">
                    <AppCard {...content} id />
                  </Grid>
                ) : null}
              </>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
