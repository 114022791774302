import { Container, Box, Grid } from "@material-ui/core";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import WANewChat from "./pages/WANewChat/WANewChat";
import UrlShorten from "./pages/UrlShorten/UrlShorten";

import "./App.css";
import "./theme.css";

function App() {
  return (
    <Box className="main_container">
      <Container>
        <Router>
          <Grid container className="main_container__content">
            <Grid
              item
              className="main_container__content__item app_header_container"
            >
              <Header />
              <Box className="main_app_container">
                <Switch>
                  <Route path="/" exact>
                    <Home />
                  </Route>
                  <Route path="/wachat">
                    <WANewChat />
                  </Route>
                  <Route path="/urlshort">
                    <UrlShorten />
                  </Route>
                </Switch>
              </Box>
            </Grid>
            <Grid item className="main_container__content__item">
              <Footer />
            </Grid>
          </Grid>
        </Router>
      </Container>
    </Box>
  );
}

export default App;
