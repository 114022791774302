import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grow,
  CardActionArea,
} from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

import "./AppCard.css";

const AppCard = (props) => {
  return (
    <Grow in timeout={1000}>
      <Card className="card">
        <CardActionArea
          className="card__container"
          {...(props.url.startsWith("http")
            ? { href: props.url, target: "blank_", rel: "noreferrer" }
            : {
                component: Link,
                to: {
                  pathname: props.url,
                  state: props,
                },
              })}
        >
          <CardMedia
            className="card__media"
            image={process.env.PUBLIC_URL + props.image}
            title={props.title}
          />
          <CardContent className="card__content">
            <Typography className="card__content__title" variant="h6">
              {props.title}
            </Typography>
            <Typography className="card__content__desc" variant="body2">
              {props.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grow>
  );
};

export default AppCard;
